// @ts-nocheck
import * as React from 'react'

export const USDC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    viewBox="0 0 676.986 223.576"
    height="25"
    width="auto">
    <defs>
      {/* eslint-disable-next-line bsky-internal/avoid-unwrapped-text */}
      <style>{'.cls-3{stroke-width:0;fill:#fff}'}</style>
    </defs>
    <path
      d="M263.785 128.967v-73.83H282.8v72.712c0 15.98 8.79 24.769 23.172 24.769 14.543 0 23.171-8.79 23.171-24.77V55.137h19.177v73.831c0 27.326-18.537 41.87-42.348 41.87-23.651 0-42.188-14.544-42.188-41.87ZM426.487 87.098c-1.118-7.032-7.03-17.419-22.213-17.419-11.505 0-19.496 7.67-19.496 16.3 0 7.192 4.476 12.624 13.265 14.542l16.3 3.357c19.976 3.995 30.683 16.619 30.683 32.6 0 17.578-14.224 34.358-40.112 34.358-28.765 0-42.03-18.538-43.787-34.837l17.9-5.114c1.117 11.825 9.587 22.692 25.887 22.692 13.584 0 20.616-6.871 20.616-15.661 0-7.351-5.435-13.264-15.183-15.342l-15.98-3.355c-17.259-3.516-28.766-14.862-28.766-31.801 0-19.018 17.42-34.678 38.515-34.678 26.686 0 37.074 16.3 39.63 28.924l-17.26 5.434ZM461.033 168.439V55.136h40.112c30.843 0 55.134 20.455 55.134 56.892 0 36.276-24.612 56.41-55.295 56.41h-39.951Zm39.313-17.578c19.657 0 35.957-12.625 35.957-38.833 0-26.528-16.142-39.312-35.798-39.312H480.21v78.145h20.136ZM568.48 111.867c0-36.915 27.645-59.127 57.05-59.127 28.764 0 45.863 16.94 50.979 36.755l-18.06 6.073c-3.354-13.743-13.903-24.77-32.92-24.77-18.697 0-37.393 13.584-37.393 41.07 0 26.527 18.216 40.59 37.554 40.59 19.017 0 29.884-12.145 33.718-24.93l17.578 5.753c-5.113 19.178-22.37 37.555-51.296 37.555-30.524 0-57.21-22.053-57.21-58.969Z"
      className="cls-3"
    />
  </svg>
)

export const USDCLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    viewBox="0 0 296.986 233.576"
    height="30"
    width="30">
    <defs>
      {/* eslint-disable-next-line bsky-internal/avoid-unwrapped-text */}
      <style>{'.cls-3{stroke-width:0;fill:#fff}'}</style>
    </defs>
    <path
      id="Logo"
      d="M111.788 0c61.74 0 111.788 50.05 111.788 111.788s-50.049 111.788-111.788 111.788S0 173.527 0 111.788 50.05 0 111.788 0Z"
      style={{
        strokeWidth: 0,
        fillRule: 'evenodd',
        fill: '#2775ca',
      }}
    />
    <path
      d="M137.33 33.073c-2.52-.805-4.581.691-4.581 3.338v6.509c0 1.774 1.337 3.794 3.003 4.404 26.757 9.8 45.904 35.519 45.904 65.629s-19.147 55.827-45.904 65.628c-1.827.67-3.003 2.459-3.003 4.405v6.508c0 2.646 2.06 4.143 4.582 3.338 33.813-10.804 58.298-42.482 58.298-79.88s-24.485-69.075-58.298-79.878Zm-46.502 3.338c0-2.647-2.061-4.143-4.582-3.338-33.814 10.804-58.299 42.482-58.299 79.88s24.485 69.075 58.299 79.879c2.52.805 4.582-.692 4.582-3.338v-6.508c0-1.775-1.337-3.795-3.004-4.405-26.756-9.8-45.903-35.519-45.903-65.628s19.147-55.828 45.903-65.629c1.667-.61 3.004-2.63 3.004-4.404v-6.51Zm24.454 20.648h-6.987a3.493 3.493 0 0 0-3.493 3.493v10.823C90.95 73.344 81.997 82.56 81.997 94.386c0 15.307 9.253 21.246 28.783 23.874 13.25 2.17 17.021 5.024 17.021 12.564 0 7.539-6.4 12.565-15.42 12.565-12.197 0-16.211-5.338-17.692-12.246-.35-1.637-1.752-2.833-3.426-2.833h-7.969c-2.007 0-3.576 1.808-3.231 3.785 2.029 11.629 9.497 20.16 24.739 22.228v11.03a3.494 3.494 0 0 0 3.493 3.494h6.987a3.494 3.494 0 0 0 3.493-3.494v-11.035c14.429-2.292 23.647-12.337 23.647-24.75 0-16.334-9.938-21.818-29.13-24.444-14.163-2.056-16.902-5.37-16.902-11.995 0-6.28 4.796-10.737 14.049-10.737 8.369 0 13.182 2.92 15.17 9.656.444 1.503 1.79 2.566 3.357 2.566h7.349c2.049 0 3.644-1.883 3.207-3.886-2.327-10.644-9.5-17.031-20.747-19.046v-11.13a3.493 3.493 0 0 0-3.493-3.493Z"
      style={{
        fill: '#fff',
        strokeWidth: 0,
        fillRule: 'evenodd',
      }}
    />
  </svg>
)
